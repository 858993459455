#chatCon {
    /*width: 25%;*/
    width: 350px;
    position: fixed; /* changed from absolute to fixed */
    bottom: 3%;
    right: 3%;
    font-family: "Josefin Sans";
    z-index: 99999999;
}

#chatCon .chat-box {
    /* display: none; */
    width: 100%;
    height: 500px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: white;
    flex-direction: column;
    box-shadow: 0px 4px 14px 3px rgba(0, 0, 0, 0.25);

}

#chatCon .chat-box .header {
    background-color: #3fda73;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    color: #fff;
    font-size: 20px;
    /*text-align: center; !* center the title *!*/
    justify-content: space-between;

}

#chatCon .chat-box .msg-area {
    overflow: hidden;
    height: 370px;
    padding: 15px;
    overflow-y: auto; /* enable vertical scroll */
    flex-grow: 1; /* occupy available space */
}

#chatCon .chat-box .msg-area .left span {
    display: inline-block;
    font-size: 17.5px;
    border-radius: 15px;
    padding: 10px;
    background-color: #8e8e93;
    color: white;
}

#chatCon .chat-box .msg-area .right {
    text-align: right;

}

#chatCon .chat-box .msg-area .right span {
    display: inline-block;
    font-size: 17.5px;
    border-radius: 15px;
    padding: 10px;
    color: white;
    background-color: #53d769;
}

#chatCon .chat-box .footer {
    position: sticky;
    bottom: 0;
    /*border-top: 1px solid #F0EAD6;*/
    background: #fff; /* same color as the chat box */
    /*padding: 0 20px;*/
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    align-items: center;
    box-shadow: 0px 4px 14px 3px rgba(0, 0, 0, 0.25);
    z-index: 1; /* ensures the footer stays above the messages */
}


#chatCon .chat-box .footer input {
    flex-grow: 1;
    border: 2px solid #8e8e93;
    padding: 10px;
    margin-right: 10px;
    border-radius: 15px;
}

/*#chatCon .chat-box .footer input:focus {*/
/*    outline: none;*/
/*}*/

#chatCon .chat-box .footer button {
    /*border: none;*/
    font-size: 22.5px;
    color: #8e8e93;
    cursor: pointer;
}

#chatCon .pop {
    width: 100%;
    height: 25%;
    cursor: pointer;
}

#chatCon .pop p {
    text-align: right;
}

#chatCon .pop img {

    border-radius: 50%;
    width: 25%;
}

.file-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    background: white;
}

.file-preview {
    position: relative;
}

.file-preview img {
    height: 50px;
}

.file-preview button {
    position: absolute;
    top: -20px;
    right: -33px;
}