
.file-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    background: white;
}

.file-preview {
    position: relative;
}

.file-preview img {
    height: 50px;
}

.file-preview button {
    position: absolute;
    top: -20px;
    right: -33px;
}
