.carousel .thumbs-wrapper {
    display: flex;
    justify-content: center;
    overflow: visible;
}

.carousel .thumbs {
    flex-direction: row;
    justify-content: center;
    margin: auto;
}

.carousel .thumb {
    border: none;
    outline: none;
}


.carousel .thumb.selected {
    border: none !important;
    /*background: red;*/
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    outline: none !important; /* Remove the default outline or border shown by some browsers */
}

.carousel .thumb:hover {
    border: none !important;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    outline: none !important; /* Remove the default outline or border shown by some browsers */
}


.carousel .carousel-status {
    visibility: hidden;
}