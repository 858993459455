
.gallery-image {
  width: 100%;
  transition: opacity 0.3s ease-in-out;
}

.image-container {
  position: relative;
  /*border: 2px solid #152737;*/
  overflow: hidden;
}

.image-container:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s ease-in-out;
}

.image-container:hover:before {
  /*background: rgba(0, 0, 0, 0.5);*/
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  visibility: hidden;
  text-align: center;
}

.image-container:hover .centered-text {
  /*visibility: visible;*/
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr) 1fr; /* Defines a 3-column grid */
  gap: 10px;
}

.gallery-images-wrapper {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}





