.instagram-gallery {
  grid-column: span 1;
}

.instagram-item {
    flex: 0 0 calc(50% - 10px);
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.instagram-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire item space, cropping if necessary */
}

.pagination {
    display: block;
    margin: 0 auto;
    padding: 2em;
    text-align: center; /* Center pagination items */
}